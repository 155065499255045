a:hover {
  text-decoration: dashed;
}

/* total width */
body::-webkit-scrollbar,
.arn-custom-scroll::-webkit-scrollbar {
  background-color: transparent;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track,
.arn-custom-scroll::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb,
.arn-custom-scroll::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button,
.arn-custom-scroll::-webkit-scrollbar-button {
  display: none;
}

.popover-wrap.is--open {
  transform: translateX(0%) translateY(-45%);
  /* transform: translateX(287%) translateY(55%); */
  box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 20%);
  opacity: 1;
}

.MuiChip-deleteIcon {
  color: #2CAB69 !important;
}

.MuiChip-deleteIcon:hover {
  color: green !important;
}

.sub-ui {
  background-color: rgb(239 239 239);
  border: 1px solid rgb(221 221 221);
  box-shadow: -10px 4px 12px rgb(0 0 0 / 15%);
  color: #2a2a2a;
  height: auto;
  padding: 5px 8px;
  position: absolute;
  width: 180px;
  top: 50%;
  left: 340px;
  transform: translateY(-50%);
}

.sub-ui>p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 32px;
}

.sub-ui p span {
  font-weight: 700;
}

.popover-wrap {
  transform: translateX(-135%) translateY(-45%);
  /* transform: translateX(100%) translateY(55%); */
  background-color: #fff;
  width: 340px;
  height: 370px;
  padding: 0px 0px;
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: none;
  opacity: 0;
}

.popover-layout {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row-reverse;
  bottom: 270px;
  margin-bottom: -359px !important;
  left: 385px;
  /* bottom: 740px;
  margin-bottom: -450px !important;
  left: 45px; */
}

/* @media (max-width: 1533px) {
  .popover-wrap.is--open {
    transform: translateX(261%) translateY(55%);
  }
} */
.arnp-list-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.arnp-list-item.is--active {
  background-color: #BBDCB382;
}

.arnp-list-item svg {
  fill: #000;
}

.arnp-list-item.is--active svg {
  fill: #05a565;
}

.no-result {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  color: #898989;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.4);
}

.arnp-overlay-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: rgb(255 255 255 / 85%);
}

.dimension+line {
  stroke: #fff;
}